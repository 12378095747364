<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Banco Central - Configuración</h4>
            <div class="small text-muted">Configurar la conexión para conectarnos al servicio</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      

            <b-tabs v-model="tabIndex" card>
                        
              <b-tab title="Principal" :title-link-class="linkClass(0)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Banco Central
                      <small>
                        <b-badge variant="success" v-if="crud.form.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12">
                      <b-row>                                                
                        <b-col md="6">
                          <b-form-group label="Código de Empresa">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.cod_empresa"                               
                                          placeholder="xxxxx">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveBCRA()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <hr>
                        </b-col> 
                        <b-col md="12">
                          <b-form-group label="CUIT / CUIL de Prueba">
                            <b-form-input type="number"
                                          size="sm"
                                          v-model="crud.form.nroCUIT"                               
                                          placeholder="Ingrese un nro de cuit/cuil sin guiones (11 dígitos)">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-button variant="secondary" size="sm" @click="testBCRA()">Probar Conexión</b-button>                          
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12" xs="12">
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Para realizar las consultas al Banco Central utilizamos el servicio 
                          <a href="https://infoexpress.com.ar/" target="_blank">Infoexpress By SLDI SRL</a>                          
                          <br>
                          Cree una cuenta y configure los datos solicitados para tener acceso a la base de datos.
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.BCRA,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: { 
          form: {
            id: 1,         
            name: '',   
            cod_empresa: 0,                       
            active: false,
            nroCUIT: '',
          },             
        },
        tabIndex: 0                  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.showBCRA()      
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },      
      showBCRA() {        
        var result = serviceAPI.mostrarBCRA(this.crud.form.id)

        result.then((response) => {
          var data = response.data
          
          this.crud.form.name = data.name
          this.crud.form.cod_empresa = data.cod_empresa 
          this.crud.form.active = data.active                        
        })
      },
      saveBCRA() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de BCRA?', {
          title: 'Guardar BCRA',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();
            var result = serviceAPI.editarBCRA(this.crud.form);

            result.then((response) => {          
              loader.hide()          
              this.showBCRA()
              this.$awn.success("Datos del BCRA guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      testBCRA() {                        
        if (this.crud.form.nroCUIT.length != 11) {          
          this.$awn.alert("No se puede realizar el test sino se ingresa un cuit válido")          
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Probar configuración de BCRA?', {
          title: 'Configuración de email',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.testBCRA(this.crud.form)

            result.then((response) => {                        
              loader.hide()
              this.showBCRA()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },            
    } 
  }
</script>