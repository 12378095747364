import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerBCRA() {
    var url = ConfigAPI.baseURL + "bcra" + Session.getToken();
    return instance.get(url);
  },
  mostrarBCRA(id) {
    var url = ConfigAPI.baseURL + "bcra/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarBCRA(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "bcra" + Session.getToken();
    return instance.post(url,params);
  },
  editarBCRA(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "bcra/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarBCRA(id) {
    var url = ConfigAPI.baseURL + "bcra/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  
  testBCRA(data){
    var params = {      
      nroCUIT: data.nroCUIT,            
    }

    var url = ConfigAPI.baseURL + "bcra-query/test" + Session.getToken();
    return instance.post(url, params);
  },  
  queryBCRACUIT(data) {    
    var params = {    
      nroCUIT: data.cuil,      
    }

    var url = ConfigAPI.baseURL + "bcra-query/cuit" + Session.getToken();
    return instance.post(url, params);
  }, 
  queryBCRADNI(data) {    
    var params = {
      nroDNI: data.documento,      
      sexo: data.sexo
    }

    var url = ConfigAPI.baseURL + "bcra-query/dni" + Session.getToken();
    return instance.post(url, params);
  },   
  
  obtenerBCRALog() {
    var url = ConfigAPI.baseURL + "bcra-log" + Session.getToken();
    return instance.get(url);
  },  
}

export default servicesAPI;
